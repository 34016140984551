import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const Impressum = (props) => {
    return (
        <Box>
            <Typography variant="h6">pharmaSuisse</Typography>
            <p>
                Schweizerischer Apothekerverband <br/>
                Société Suisse des Pharmaciens <br/>
                Società Svizzera die Farmacisti
            </p>

            <p>
                Stationsstrasse 12 <br/>
                CH-3097 Bern-Liebefeld <br/>
                Tel. +41 (0)31 978 58 58 <br/>
                info@pharmaSuisse.org <br/><br/>

            </p>


            <Typography variant="h6">Websites | Sites de web</Typography>
            <p>
                www.pharmaSuisse.org <br/>
                www.ihre-apotheke.ch <br/>
                www.votre-pharmacie.ch <br/>
                www.vostra-farmacia.ch <br/><br/>

                www.impfapotheke.ch <br/>
                www.vaccinationenpharmacie.ch <br/>
                www.vaccinazioneinfarmacia.ch <br/><br/>

                Facebook <br/>
                www.facebook.com → pharmaSuisse <br/>
                www.facebook.com → Ihre Apotheke / Votre pharmacie / Vostra farmacia <br/><br/>

                Twitter <br/>
                @pharma_suisse <br/><br/>

                YouTube <br/>
                www.youTube.com → pharmaSuisse
            </p>
        </Box>
    );
};

export default Impressum