import { Table, Tag, Space, Typography } from "antd";
import PropTypes from "prop-types";
import { format } from "date-fns";

const { Link } = Typography;


const BatchLogTable = (props) => {
    const columns = [
        {
            title: "Ausführungszeitpunkt",
            dataIndex: "batch_Start_Date",
            render: (time) => {
                return format(new Date(time), "dd.MM.yyyy HH:mm T");
            },
        },
        {
            title: "Bezeichnung",
            dataIndex: "batch_Name",
        },
        {
            title: "Job-Id",
            dataIndex: "job_Id",
        },
        {
            title: "Status",
            dataIndex: "batch_Status",
            render: (status) => {
                let color = status === "FAILED" ? "volcano" : "green";
                let txt =
                    status === "FAILED" ? "FEHLGESCHLAGEN" : "ERFOLGREICH";
                return (
                    <Tag color={color} key={status}>
                        {txt}
                    </Tag>
                );
            },
        },
        {
            title: "Aktionen",
            key: "aktionen",
            render: (row) => {
                return (
                    <Space size="middle">
                        <Link onClick={() => props.showInfo(row)}>Infos</Link>
                    </Space>
                );
            },
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={props.data}
            rowKey="job_Id"
            // rowClassName={(row) => row.deleted && classes.deletedRow}
            pagination={true}
        />
    );
};

export default BatchLogTable;

BatchLogTable.propTypes = {
    data: PropTypes.array.isRequired,
    auth: PropTypes.object.isRequired,
    showInfo: PropTypes.func.isRequired,
};
