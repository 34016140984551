import React, { useEffect } from "react";
import { Modal } from "antd";
import {  Box } from "@material-ui/core";
import axios from "axios";
import BatchLogTable from "./BatchLogTable";
import AuthenticationContext from "../../Contexts/AuthenticationContext";

const Jobs = (props) => {
    const isMounted = React.useRef(false);
    const auth = React.useContext(AuthenticationContext);

    const [state, setState] = React.useState({
        batch_control: [],
    });

    useEffect(() => {
        isMounted.current = true;

        axios
            .get("/audits/batch-log")
            .then((response) => {
                setState({
                    ...state,
                    batch_control: response.data,
                });
            })
            .catch((reason) => {
                Modal.error({
                    title: "Fehler",
                    content:
                        typeof reason.response?.data === "string"
                            ? reason.response?.data
                            : "Es ist ein Verarbeitungsfehler aufgetreten.",
                });
            });

        return () => (isMounted.current = false);
    }, [state]);

    return (
        <Box>
            <BatchLogTable data={state.batch_control} auth={auth} />
        </Box>
    );
};

export default Jobs;
