import React from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import AuthenticationContext from "../../Contexts/AuthenticationContext";
import { format } from "date-fns";
import { Table } from "antd";

const JobDetail = (props) => {
    const params = useParams();
    const isMounted = React.useRef(false);
    const auth = React.useContext(AuthenticationContext);

    const jobId = params.jobId;

    const [state, setState] = React.useState({
        jobLogs: [],
    });

    React.useEffect(() => {
        isMounted.current = true;

        axios
            .get(`/audits/jobs/${jobId}`, {
                headers: {
                    Authorization: `Bearer ${auth.token}`,
                },
            })
            .then((response) => {
                if (isMounted.current)
                    setState((prev) => ({
                        ...prev,
                        jobLogs: response.data,
                    }));
            });

        return () => (isMounted.current = false);
    }, [auth.token, jobId]);

    const columns = [
        {
            title: "EventLogId",
            dataIndex: "event_Log_Id",
        },
        {
            title: "Job_Id",
            dataIndex: "job_Id",
        },
        {
            title: "Entity",
            dataIndex: "entity",
        },
        {
            title: "Time_Start",
            dataIndex: "time_Start",
            render: (time) => format(new Date(time), "dd.MM.yyyy HH:mm T"),
        },
        {
            title: "Time_End",
            dataIndex: "time_End",
            render: (time) => format(new Date(time), "dd.MM.yyyy HH:mm T"),
        },
        {
            title: "rows_Before",
            dataIndex: "rows_Before",
        },
        {
            title: "rows_After",
            dataIndex: "rows_After",
        },
        {
            title: "note",
            dataIndex: "note",
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={state.jobLogs}
            rowKey="event_Log_Id"
            pagination={false}
        />
    );
};

export default JobDetail;
