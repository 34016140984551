import { makeStyles } from "@material-ui/core/styles";
import { Table, Tag, Space, Typography, Modal } from "antd";
import { Popconfirm } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

const { Link } = Typography;

const useStyles = makeStyles((theme) => ({
    deletedRow: {
        color: "rgb(0 0 0 / 50%)",
    },
}));

const ProvidersTable = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Ort",
            render: (row) => `${row.zip_Code} ${row.city}`,
        },
        {
            title: "Strasse",
            dataIndex: "street",
        },
        {
            title: "Status",
            dataIndex: "deleted",
            render: (deleted) => {
                let color = deleted ? "volcano" : "green";
                let txt = deleted ? "DEAKTIVIERT" : "AKTIV";
                return (
                    <Tag color={color} key={deleted}>
                        {txt}
                    </Tag>
                );
            },
        },
        {
            title: "Aktionen",
            key: "aktionen",
            render: (row) => {
                return (
                    <Space size="middle">
                        <Link onClick={() => props.initEditProvider(row)}>
                            Bearbeiten
                        </Link>
                        <Link onClick={() => showSuperusers(row.provider_Id)}>
                            Benutzer verwalten
                        </Link>
                        {row.deleted && (
                            <Popconfirm
                                title="Möchten Sie diesen Validator wirklich wieder aktivieren?"
                                icon={
                                    <QuestionCircleOutlined
                                        style={{ color: "green" }}
                                    />
                                }
                                onConfirm={() =>
                                    setProviderActivateStatus(row, true)
                                }
                                okText="Ja"
                                cancelText="Nein"
                            >
                                <Link>Aktivieren</Link>
                            </Popconfirm>
                        )}
                        {!row.deleted && (
                            <Popconfirm
                                title="Möchten Sie diesen Validator wirklich deaktivieren?"
                                icon={
                                    <QuestionCircleOutlined
                                        style={{ color: "red" }}
                                    />
                                }
                                onConfirm={() =>
                                    setProviderActivateStatus(row, false)
                                }
                                okText="Ja"
                                cancelText="Nein"
                            >
                                <Link>Deaktivieren</Link>
                            </Popconfirm>
                        )}
                    </Space>
                );
            },
        },
    ];

    const setProviderActivateStatus = async (provider, active) => {
        const url = active ? "/providers/activate" : "/providers/deactivate";
        axios
            .put(`${url}/${provider.provider_Id}`, null, {
                headers: {
                    Authorization: `Bearer ${props.auth.token}`,
                },
            })
            .then((response) => {
                props.setActive(provider, active);
                if (!active)
                    Modal.success({
                        title: "Validator deaktiviert",
                        content:
                            "Der Validator wurde erfolgreich deaktiviert und ist nicht mehr in der Lage Daten zu liefern. Sie können den Validator jederzeit wieder aktivieren.",
                    });
            })
            .catch((reason) => {
                Modal.error({
                    title: "Fehler",
                    content: "Es ist ein unerwarteter Fehler aufgetreten.",
                });
            });
    };

    const showSuperusers = (providerId) => {
        history.push(`/users/${providerId}`);
    };

    return (
        <Table
            columns={columns}
            dataSource={props.data}
            rowKey="provider_Id"
            rowClassName={(row) => row.deleted && classes.deletedRow}
            pagination={true}
        />
    );
};

export default ProvidersTable;

ProvidersTable.propTypes = {
    data: PropTypes.array.isRequired,
    auth: PropTypes.object.isRequired,
    setActive: PropTypes.func.isRequired,
    initEditProvider: PropTypes.func.isRequired,
};
