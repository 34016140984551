import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import UsersTable from "./UsersTable";
import UserForm from "./UserForm";
import AddIcon from "@material-ui/icons/Add";
import AuthenticationContext from "../../Contexts/AuthenticationContext";
import axios from "axios";
import { Modal, Space } from "antd";
import { useParams, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

const initialUser = {
    email: "",
    first_Name: "",
    last_Name: "",
};

const Users = (props) => {
    const auth = React.useContext(AuthenticationContext);
    const isMounted = React.useRef(false);
    const params = useParams();
    const history = useHistory();

    const currentProviderId =
        auth.role === "ADMIN"
            ? parseInt(params.providerId)
            : auth.provider.provider_Id;

    const [state, setState] = React.useState({
        users: [],
        isLoading: true,
        newUser: null,
        editUser: null,
        provider: null,
    });

    useEffect(() => {
        isMounted.current = true;

        axios
            .get(`/users/providers/${currentProviderId}`, {
                headers: {
                    Authorization: `Bearer ${auth.token}`,
                },
            })
            .then((response) => {
                if (isMounted.current)
                    setState((prev) => ({
                        ...prev,
                        users: [...response.data],
                        isLoading: false,
                    }));
            });

        axios
            .get(`/providers/${currentProviderId}`, {
                headers: {
                    Authorization: `Bearer ${auth.token}`,
                },
            })
            .then((response) => {
                if (isMounted.current)
                    setState((prev) => ({
                        ...prev,
                        provider: response.data,
                    }));
            });

        return () => (isMounted.current = false);
    }, [currentProviderId, auth.token]);

    if (auth.role === "ADMIN" && !params.providerId)
        return <h3>Es wurde kein Validator ausgewählt.</h3>;

    const setActive = (user, active) => {
        setState((prev) => {
            const ix = prev.users.findIndex((u) => u.user_Id === user.user_Id);
            prev.users[ix].deleted = !active;
            return {
                ...prev,
                users: [...prev.users],
            };
        });
    };

    const initNewUser = () => {
        setState((prev) => ({
            ...prev,
            newUser: {
                ...initialUser,
            },
        }));
    };

    const doneCreatingUser = (newUser) => {
        setState((prev) => ({
            ...prev,
            newUser: null,
            users: [...prev.users, newUser],
        }));
    };

    const createUser = (data) => {
        axios
            .post(
                "/users",
                {
                    provider_Id: data.provider_Id,
                    first_Name: data.first_Name,
                    last_Name: data.last_Name,
                    email: data.email,
                },
                {
                    headers: {
                        Authorization: `Bearer ${auth.token}`,
                    },
                }
            )
            .then((response) => {
                Modal.success({
                    title: "Benutzer erstellt",
                    content:
                        "Der Benutzer wurde erfolgreich erstellt. Eine Bestätigung wurde dem Benutzer direkt per E-Mail zugestellt.",
                });
                doneCreatingUser(response.data);
                redirectToUsersProvider(response.data);
            })
            .catch((reason) => {
                Modal.error({
                    title: "Fehler",
                    content:
                        typeof reason.response?.data === "string"
                            ? reason.response?.data
                            : "Es ist ein Verarbeitungsfehler aufgetreten.",
                });
            });
    };

    const initEditUser = (user) => {
        setState((prev) => ({
            ...prev,
            editUser: { ...user },
        }));
    };

    const doneUpdatingUser = (user) => {
        setState((prev) => {
            const ix = prev.users.findIndex((u) => u.user_Id === user.user_Id);
            prev.users[ix] = user;
            return {
                ...prev,
                users: [...prev.users],
                editUser: null,
            };
        });
    };

    const editUserSubmit = (data) => {
        const newUser = { ...state.editUser, ...data };
        axios
            .put(`/users/${state.editUser.user_Id}`, newUser, {
                headers: {
                    Authorization: `Bearer ${auth.token}`,
                },
            })
            .then((response) => {
                Modal.success({
                    title: "Benutzer aktualisiert",
                    content: "Der Benutzer wurde erfolgreich aktualisiert.",
                });
                doneUpdatingUser(response.data);
                redirectToUsersProvider(newUser);
            })
            .catch((reason) => {
                Modal.error({
                    title: "Fehler",
                    content:
                        typeof reason.response?.data === "string"
                            ? reason.response?.data
                            : "Es ist ein Verarbeitungsfehler aufgetreten.",
                });
            });
    };

    const redirectToUsersProvider = (user) => {
        history.push(`/users/${user.provider_Id}`);
    };

    const abortUserEdit = () => {
        setState((prev) => ({
            ...prev,
            editUser: null,
        }));
    };

    const abortUserCreation = () => {
        setState((prev) => ({
            ...prev,
            newUser: null,
        }));
    };

    if (state.newUser) {
        return (
            <Box>
                <Typography variant="h6">Neuer Benutzer</Typography>
                <Box mt={2} />
                <UserForm
                    auth={auth}
                    submitUser={createUser}
                    abort={abortUserCreation}
                    providerId={currentProviderId}
                    provider={state.provider}
                />
            </Box>
        );
    }

    if (state.editUser) {
        return (
            <Box>
                <Typography variant="h6">Benutzer bearbeiten</Typography>
                <Box mt={2} />
                <UserForm
                    auth={auth}
                    submitUser={editUserSubmit}
                    abort={abortUserEdit}
                    initialValues={state.editUser}
                    isEditing
                    providerId={currentProviderId}
                    provider={state.provider}
                />
            </Box>
        );
    }

    return (
        <Box>
            <Space direction="vertical" size="large">
                <Typography variant="h5">
                    Validator: {state.provider?.name}
                </Typography>
                <Typography variant="h6">Benutzerverwaltung</Typography>
            </Space>

            <UsersTable
                data={state.users}
                auth={auth}
                setActive={setActive}
                initEditUser={initEditUser}
            />
            <Box pt={2}>
                <Space>
                    <Button
                        type="submit"
                        variant="outlined"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={initNewUser}
                    >
                        Neuer Benutzer
                    </Button>
                    {auth.role === "ADMIN" && (
                        <Link to="/providers">
                            <Button>Zurück</Button>
                        </Link>
                    )}
                </Space>
            </Box>
        </Box>
    );
};

export default Users;

Users.propTypes = {};
