import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ProvidersTable from "./ProvidersTable";
import ProviderForm from "./ProviderForm";
import AddIcon from "@material-ui/icons/Add";
import AuthenticationContext from "../../Contexts/AuthenticationContext";
import axios from "axios";
import { Modal } from "antd";

const initialProvider = {
    name: "",
    street: "",
    zip_Code: "",
    city: "",
};

const Providers = (props) => {
    const auth = React.useContext(AuthenticationContext);
    const isMounted = React.useRef(false);

    const [state, setState] = React.useState({
        providers: [],
        isLoading: true,
        newProvider: null,
        editProvider: null,
    });

    const getProviders = () => {
        setState((prev) => ({ ...prev, isLoading: true }));
        return axios.get(`/providers`, {
            headers: {
                Authorization: `Bearer ${auth.token}`,
            },
        });
    };

    const setActive = (provider, active) => {
        setState((prev) => {
            const ix = prev.providers.findIndex(
                (u) => u.provider_Id === provider.provider_Id
            );
            prev.providers[ix].deleted = !active;
            return {
                ...prev,
                providers: [...prev.providers],
            };
        });
    };

    const initNewProvider = () => {
        setState((prev) => ({
            ...prev,
            newProvider: {
                ...initialProvider,
            },
        }));
    };

    const doneCreatingProvider = (newProvider) => {
        setState((prev) => ({
            ...prev,
            newProvider: null,
            providers: [...prev.providers, newProvider],
        }));
    };

    const createProvider = (newData) => {
        axios
            .post(
                "/providers",
                {
                    ...newData,
                    provider_Id: auth.provider.provider_Id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${auth.token}`,
                    },
                }
            )
            .then((response) => {
                Modal.success({
                    title: "Validator erstellt",
                    content:
                        "Der Validator wurde erfolgreich erstellt.",
                });
                doneCreatingProvider(response.data);
            })
            .catch((reason) => {
                Modal.error({
                    title: "Fehler",
                    content:
                        typeof reason.response?.data === "string"
                            ? reason.response?.data
                            : "Es ist ein Verarbeitungsfehler aufgetreten.",
                });
            });
    };

    const initEditProvider = (provider) => {
        setState((prev) => ({
            ...prev,
            editProvider: { ...provider },
        }));
    };

    const doneUpdatingProvider = (provider) => {
        setState((prev) => {
            const ix = prev.providers.findIndex(
                (u) => u.provider_Id === provider.provider_Id
            );
            prev.providers[ix] = provider;
            return {
                ...prev,
                providers: [...prev.providers],
                editProvider: null,
            };
        });
    };

    const editProviderSubmit = (data) => {
        const newProvider = { ...state.editProvider, ...data };
        axios
            .put(`/providers/${state.editProvider.provider_Id}`, newProvider, {
                headers: {
                    Authorization: `Bearer ${auth.token}`,
                },
            })
            .then((response) => {
                Modal.success({
                    title: "Validator aktualisiert",
                    content: "Der Validator wurde erfolgreich aktualisiert.",
                });
                doneUpdatingProvider(response.data);
            })
            .catch((reason) => {
                Modal.error({
                    title: "Fehler",
                    content:
                        typeof reason.response?.data === "string"
                            ? reason.response?.data
                            : "Es ist ein Verarbeitungsfehler aufgetreten.",
                });
            });
    };

    const abortProviderEdit = () => {
        setState((prev) => ({
            ...prev,
            editProvider: null,
        }));
    };

    const abortProviderCreation = () => {
        setState((prev) => ({
            ...prev,
            newProvider: null,
        }));
    };

    useEffect(() => {
        isMounted.current = true;

        getProviders().then((response) => {
            if (isMounted.current)
                setState((prev) => ({
                    ...prev,
                    providers: [...response.data],
                    isLoading: false,
                }));
        });

        return () => (isMounted.current = false);
    }, []);

    if (state.newProvider) {
        return (
            <Box>
                <Typography variant="h6">Neuer Validator</Typography>
                <Box mt={2} />
                <ProviderForm
                    auth={auth}
                    submitProvider={createProvider}
                    abort={abortProviderCreation}
                />
            </Box>
        );
    }

    if (state.editProvider) {
        return (
            <Box>
                <Typography variant="h6">Validator bearbeiten</Typography>
                <Box mt={2} />
                <ProviderForm
                    auth={auth}
                    submitProvider={editProviderSubmit}
                    abort={abortProviderEdit}
                    initialValues={state.editProvider}
                    isEditing
                />
            </Box>
        );
    }

    return (
        <Box>
            <Typography variant="h6">Validatoren verwalten</Typography>
            <ProvidersTable
                data={state.providers}
                auth={auth}
                setActive={setActive}
                initEditProvider={initEditProvider}
            />

            <Box pt={2}>
                <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={initNewProvider}
                >
                    Neuer Validator
                </Button>
            </Box>
        </Box>
    );
};

export default Providers;
