import { useState } from "react";

export default function useAuthentication() {
    const logout = () => {
        sessionStorage.removeItem("authentication");
        setAuthentication(null);
    };

    const getAuthentication = () => {
        const authString = sessionStorage.getItem("authentication");
        const userAuthentication = JSON.parse(authString);

        return { ...userAuthentication, logout: logout };
    };

    const [authentication, setAuthentication] = useState(getAuthentication());

    const saveAuthentication = (auth) => {
        sessionStorage.setItem("authentication", JSON.stringify(auth));
        setAuthentication({ ...auth, logout });
    };

    return {
        setAuthentication: saveAuthentication,
        authentication,
    };
}
