import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import "../../fonts/fonts.css";
import "./App.css";
import "antd/dist/antd.css";
import Dashboard from "../Dashboard/Dashboard";
import Login from "../Login/Login";
import AuthenticationContext from "../../Contexts/AuthenticationContext";
import useAuthentication from "./useAuthentication";
import { Route, Switch } from "react-router-dom";
import PasswordReset from "../PasswordReset/PasswordReset";

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            "Myriad Pro",
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
    },
    palette: {
        primary: {
            main: "#009a53",
        },
    },
});

const App = () => {
    const { authentication, setAuthentication } = useAuthentication();

    return (
        <ThemeProvider theme={theme}>
            <div className="wrapper">
                <BrowserRouter>
                    <Switch>
                        <Route path="/setNewPassword/:token">
                            <PasswordReset />
                        </Route>
                        <Route>
                            {!authentication || !authentication.token ? (
                                <Login setAuthentication={setAuthentication} />
                            ) : (
                                <AuthenticationContext.Provider
                                    value={authentication}
                                >
                                    <Dashboard />
                                </AuthenticationContext.Provider>
                            )}
                        </Route>
                    </Switch>
                </BrowserRouter>
            </div>
        </ThemeProvider>
    );
};

export default App;
