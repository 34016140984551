import React from "react";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { Form, Input, Space } from "antd";
import axios from "axios";
import AuthenticationContext from "../../Contexts/AuthenticationContext";
import { Select } from "antd";

const { Option } = Select;

const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 12 },
};
const tailLayout = {
    wrapperCol: { offset: 10, span: 12 },
};

const UserForm = (props) => {
    const auth = React.useContext(AuthenticationContext);
    const isMounted = React.useRef(false);

    const [state, setState] = React.useState({
        allProviders: [],
    });

    React.useEffect(() => {
        isMounted.current = true;

        if (auth.role === "ADMIN") {
            axios
                .get(`/providers`, {
                    headers: {
                        Authorization: `Bearer ${auth.token}`,
                    },
                })
                .then((response) => {
                    if (isMounted.current)
                        setState((prev) => ({
                            ...prev,
                            allProviders: response.data,
                        }));
                });
        }

        return () => (isMounted.current = false);
    }, [auth.token, auth.role]);

    return (
        <Grid container>
            <Grid item xs={8}>
                <Form
                    {...layout}
                    name="basic"
                    initialValues={{
                        ...props.initialValues,
                        provider_Id: props.providerId,
                    }}
                    onFinish={props.submitUser}
                    onFinishFailed={() => {}}
                >
                    <Form.Item
                        label="E-Mail"
                        name="email"
                        rules={[
                            {
                                required: true,
                                type: "email",
                                message:
                                    "Bitte geben Sie eine gültige E-Mail Adresse ein!",
                            },
                        ]}
                        type="email"
                    >
                        <Input disabled={props.isEditing} />
                    </Form.Item>

                    <Form.Item
                        label="Vorname"
                        name="first_Name"
                        rules={[
                            {
                                required: true,
                                message: "Bitte geben Sie einen Vornamen ein!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Nachname"
                        name="last_Name"
                        rules={[
                            {
                                required: true,
                                message: "Bitte geben Sie einen Nachnamen ein!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Validator"
                        name="provider_Id"
                        rules={[
                            {
                                required: true,
                                message:
                                    "Bitte wählen Sie einen Validator aus!",
                            },
                        ]}
                    >
                        {auth.role === "SUPERUSER" ? (
                            <span className="ant-form-text">
                                {props.provider?.name}
                            </span>
                        ) : (
                            state.allProviders.length > 0 && (
                                <Select
                                    showSearch
                                    placeholder="Zum Suchen tippen..."
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                        optionA.children
                                            .toLowerCase()
                                            .localeCompare(
                                                optionB.children.toLowerCase()
                                            )
                                    }
                                >
                                    {state.allProviders.map((p) => (
                                        <Option
                                            key={p.provider_Id}
                                            value={p.provider_Id}
                                        >
                                            {p.name}
                                        </Option>
                                    ))}
                                </Select>
                            )
                        )}
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Space>
                            <Button onClick={props.abort}>Zurück</Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                type="submit"
                            >
                                {props.isEditing ? "Speichern" : "Erstellen"}
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Grid>
        </Grid>
    );
};

export default UserForm;

UserForm.propTypes = {
    submitUser: PropTypes.func.isRequired,
    abort: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    isEditing: PropTypes.bool,
    providerId: PropTypes.any.isRequired,
};
