import React from "react";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { Form, Input, Space, Tooltip, Switch } from "antd";

const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 12 },
};
const tailLayout = {
    wrapperCol: { offset: 10, span: 12 },
};

const ProviderForm = (props) => {
    return (
        <Grid container>
            <Grid item xs={8}>
                <Form
                    {...layout}
                    name="basic"
                    initialValues={{ ...props.initialValues }}
                    onFinish={props.submitProvider}
                    onFinishFailed={() => {}}
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: "Bitte geben Sie einen Namen ein!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Strasse"
                        name="street"
                        rules={[
                            {
                                required: true,
                                message: "Bitte geben Sie einen Strasse ein!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="PLZ"
                        name="zip_Code"
                        rules={[
                            {
                                required: true,
                                message:
                                    "Bitte geben Sie die Postleitzahl ein!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Stadt"
                        name="city"
                        rules={[
                            {
                                required: true,
                                message: "Bitte geben Sie die Stadt ein!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Space>
                            <Button onClick={props.abort}>Zurück</Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                type="submit"
                            >
                                {props.isEditing ? "Speichern" : "Erstellen"}
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Grid>
        </Grid>
    );
};

export default ProviderForm;

ProviderForm.propTypes = {
    submitProvider: PropTypes.func.isRequired,
    abort: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    isEditing: PropTypes.bool,
};
