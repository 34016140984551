import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";

const UserInfo = (props) => {
    return (
        <Box>
            <Typography variant="h6">Benutzerinformation</Typography>
            {props.user.firstName} {props.user.lastName} <br />
            {props.user.email} <br />

            <Box pt={2} />

            <Typography variant="h6">Validator</Typography>
            {props.user.provider.name} <br />
            {props.user.provider.street} <br />
            {props.user.provider.zip_Code} {props.user.provider.city}
        </Box>
    );
};

export default UserInfo


UserInfo.propTypes = {
    user: PropTypes.object.isRequired
}