import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./Uploads.css";
import { Button, Divider } from "@material-ui/core";
import UploadService from "./UploadService";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Box, Typography, withStyles, Grid, Paper } from "@material-ui/core";
import AuthenticationContext from "../../Contexts/AuthenticationContext";
import UploadHistoryTable from "./UploadHistoryTable";
import { Alert } from "antd";
import axios from "axios";
import { Upload, Button as Antbutton } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        width: 300,
        height: 15,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: "#EEEEEE",
    },
    bar: {
        borderRadius: 5,
        backgroundColor: "#1a90ff",
    },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: "center",
        flexDirection: "column",
        display: "flex",
    },
    errorBox: {
        color: "#611a15",
        backgroundColor: "#fdecea",
        borderRadius: 5,
        padding: 20,
    },
    successBox: {
        color: "#611a15",
        backgroundColor: "#edf7ed",
        borderRadius: 5,
        padding: 20,
    },
}));

const Uploads = (props) => {
    const classes = useStyles();
    const isMounted = useRef(false);
    const auth = React.useContext(AuthenticationContext);

    const [state, setState] = React.useState({
        currentFile: null,
        progress: 0,
        isError: false,
        isSuccess: false,
        message: null,
        uploads: [],
    });

    useEffect(() => {
        isMounted.current = true;

        getUploads().then((response) => {
            if (isMounted.current)
                setState((prev) => ({ ...prev, uploads: [...response.data] }));
        });

        return () => (isMounted.current = false);
    }, []);

    const getUploads = async () => {
        return axios.get("/user-uploads", {
            headers: {
                Authorization: `Bearer ${auth.token}`,
            },
        });
    };

    const onFileRemove = (file) => {
        setState({
            ...state,
            currentFile: null,
        });
    };

    const beforeUpload = (file) => {
        setState((state) => ({
            ...state,
            currentFile: file,
        }));
        return false;
    };

    const upload = () => {
        setState({
            ...state,
            progress: 0,
            uploading: true,
        });

        UploadService.upload(auth.token, state.currentFile, (event) => {
            setState({
                ...state,
                progress: Math.round((100 * event.loaded) / event.total),
            });
        })
            .then((response) => {
                setState({
                    ...state,
                    message: response.data.message,
                    isError: false,
                    isSuccess: response.status === 200,
                    uploading: false,
                });
            })
            .catch((reason) => {
                const msg =
                    typeof reason.response?.data === "string"
                        ? reason.response?.data
                        : "Beim Upload ist ein Fehler aufgetreten.";
                setState((prev) => ({
                    ...prev,
                    message: msg,
                    currentFile: null,
                    isError: true,
                    uploading: false,
                }));
            })
            .finally(() => {
                getUploads().then((response) => {
                    if (isMounted.current)
                        setState((prev) => ({
                            ...prev,
                            uploads: [...response.data],
                            currentFile: null,
                        }));
                });
            });
    };

    return (
        <Grid container className={classes.root}>
            <Paper style={{ maxWidth: 800 }}>
                <Grid>
                    <Grid container justify="center">
                        <Grid item>
                            <Upload
                                fileList={
                                    state.currentFile ? [state.currentFile] : []
                                }
                                onFileRemove={onFileRemove}
                                beforeUpload={beforeUpload}
                                accept=".xml"
                                maxCount={1}
                            >
                                <Antbutton
                                    icon={<UploadOutlined />}
                                    style={{ marginTop: 15 }}
                                >
                                    Datei auswählen
                                </Antbutton>
                            </Upload>
                        </Grid>

                        <Grid container justify="center">
                            <Box pt={2}>
                                {state.currentFile && (
                                    <Box>
                                        <Box>
                                            <BorderLinearProgress
                                                variant="determinate"
                                                value={state.progress}
                                            />
                                        </Box>
                                        <Box>
                                            <Typography color="textSecondary">{`${state.progress}%`}</Typography>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Grid>

                        <Divider />

                        <Grid container justify="center">
                            <Box pt={2} pb={4}>
                                <Button
                                    className="btn-upload"
                                    color="primary"
                                    variant="contained"
                                    component="span"
                                    disabled={!state.currentFile}
                                    onClick={upload}
                                >
                                    {state.uploading
                                        ? "Uploading..."
                                        : "Upload starten"}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container justify="center" style={{ padding: 20 }}>
                        <Box pb={3}>
                            {state.isError && (
                                <Alert
                                    type="error"
                                    message="Verarbeitungsfehler (Upload fehlgeschlagen)"
                                    description={state.message}
                                    closable
                                />
                            )}

                            {state.isSuccess && (
                                <Alert
                                    type="success"
                                    message="Der Upload war erfolgreich"
                                    closable
                                />
                            )}
                        </Box>
                    </Grid>
                </Grid>

                <Grid container justify="center">
                    <Box p={1}>
                        {state.uploads && state.uploads.length > 0 && (
                            <UploadHistoryTable data={[...state.uploads]} />
                        )}
                    </Box>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default Uploads;
