import { makeStyles } from "@material-ui/core/styles";
import { Table, Tag, Space, Typography, Modal } from "antd";
import { Popconfirm } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import PropTypes from "prop-types";

const { Link } = Typography;

const useStyles = makeStyles((theme) => ({
    deletedRow: {
        color: "rgb(0 0 0 / 50%)",
    },
}));

const ROLES = [ "ADMIN", "SUPERUSER", "STANDARD"]

const UsersTable = (props) => {
    const classes = useStyles();

    const columns = [
        {
            title: "Name",
            key: "name",
            render: (data) => `${data.last_Name} ${data.first_Name}`,
            sorter: (a, b) => `${a.last_Name} ${a.first_Name}` < `${b.last_Name} ${b.first_Name}` ? 1 : -1
        },
        {
            title: "E-Mail",
            dataIndex: "email",
            sorter: (a, b) => a.email < b.email ? 1 : -1
        },
        {
            title: "Status",
            dataIndex: "deleted",
            render: (deleted) => {
                let color = deleted ? "volcano" : "green";
                let txt = deleted ? "DEAKTIVIERT" : "AKTIV";
                return (
                    <Tag color={color} key={deleted}>
                        {txt}
                    </Tag>
                );
            },
            sorter: (a, b) => a.deleted > b.deleted ? 1 : -1
        },
        {
            title: "Rolle",
            dataIndex: "role",
            render: (role) => {
                let color = role === "STANDARD" ? "default" : "processing";
                return (
                    <Tag color={color} key={role}>
                        {role}
                    </Tag>
                );
            },
            sorter: (a, b) => ROLES.indexOf(a.role) - ROLES.indexOf(b.role)
        },
        {
            title: "Aktionen",
            key: "aktionen",
            render: (row) => {
                return (
                    <Space size="middle">
                        <Link onClick={() => props.initEditUser(row)}>
                            Bearbeiten
                        </Link>
                        {row.deleted && (
                            <Popconfirm
                                title="Möchten Sie diesen Benutzer wirklich wieder aktivieren?"
                                icon={
                                    <QuestionCircleOutlined
                                        style={{ color: "green" }}
                                    />
                                }
                                onConfirm={() => setUserActiveStatus(row, true)}
                                okText="Ja"
                                cancelText="Nein"
                            >
                                <Link>Aktivieren</Link>
                            </Popconfirm>
                        )}
                        {!row.deleted && (
                            <Popconfirm
                                title="Möchten Sie diesen Benutzer wirklich deaktivieren?"
                                icon={
                                    <QuestionCircleOutlined
                                        style={{ color: "red" }}
                                    />
                                }
                                onConfirm={() =>
                                    setUserActiveStatus(row, false)
                                }
                                okText="Ja"
                                cancelText="Nein"
                            >
                                <Link>Deaktivieren</Link>
                            </Popconfirm>
                        )}
                    </Space>
                );
            },
        },
        {
            title: "Passwort zurücksetzen",
            key: "resetPassword",
            render: (user) => {
                return (
                    <Space size="middle">
                        <Popconfirm
                            title={
                                <div>
                                    <span style={{ fontWeight: "bold" }}>
                                        Möchten Sie wirklich das Passwort
                                        zurücksetzen?
                                    </span>
                                    <br />
                                    <br />
                                    Dem Benutzer wird dadurch ein E-Mail
                                    zugesendet mit welchem sie/er das Passwort
                                    neu setzen kann.
                                </div>
                            }
                            icon={
                                <QuestionCircleOutlined
                                    style={{ color: "red" }}
                                />
                            }
                            onConfirm={() => resetPassword(user)}
                            okText="Ja"
                            cancelText="Nein"
                        >
                            <Link>Passwort zurücksetzen</Link>
                        </Popconfirm>
                    </Space>
                );
            },
        },
    ];

    const setUserActiveStatus = async (user, active) => {
        const config = {
            headers: {
                Authorization: `Bearer ${props.auth.token}`,
                Accept: "application/json",
            },
        };

        const func = active
            ? () => axios.put(`/users/activate/${user.user_Id}`, null, config)
            : () => axios.delete(`/users/${user.user_Id}`, config);

        func()
            .then((response) => {
                props.setActive(user, active);
                if (!active)
                    Modal.success({
                        title: "Benutzer deaktiviert",
                        content:
                            "Der Benutzer wurde erfolgreich deaktiviert und ist nicht mehr in der Lage Daten zu liefern. Sie können den Benutzer jederzeit wieder aktivieren.",
                    });
            })
            .catch((reason) => {
                Modal.error({
                    title: "Fehler",
                    content: "Es ist ein unerwarteter Fehler aufgetreten.",
                });
            });
    };

    const resetPassword = async (user) => {
        axios
            .post(`/users/requestPasswordReset/${user.user_Id}`, null, {
                headers: {
                    Authorization: `Bearer ${props.auth.token}`,
                },
            })
            .then((response) => {
                Modal.success({
                    title: "Aktion erfolgreich",
                    content:
                        "Dem Benutzer wurden die Instruktionen zum Zurücksetzen des Passworts direkt per E-Mail zugestellt.",
                });
            })
            .catch((reason) => {
                Modal.error({
                    title: "Fehler",
                    content:
                        "Während dem Zurücksetzen des Passwortes ist ein Fehler aufgetreten.",
                });
            });
    };

    return (
        <Table
            columns={columns}
            dataSource={props.data}
            rowKey="user_Id"
            rowClassName={(row) => row.deleted && classes.deletedRow}
            pagination={true}
        />
    );
};

export default UsersTable;

UsersTable.propTypes = {
    data: PropTypes.array.isRequired,
    auth: PropTypes.object.isRequired,
    setActive: PropTypes.func.isRequired,
    initEditUser: PropTypes.func.isRequired,
};
