import axios from "axios";

class UploadService {
    upload(token, file, onUploadProgress) {
        let formData = new FormData();

        formData.append("file", file);

        return axios.post("/uploads-generic", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
            },
            onUploadProgress,
        });
    }
}

export default new UploadService();
