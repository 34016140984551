import { format } from "date-fns";
import { Table, Tag } from "antd";

const columns = [
    {
        title: "Upload-Zeit",
        dataIndex: "upload_Time",
        key: "upload_Time",
        render: (uploadTime) =>
            format(new Date(uploadTime), "dd.MM.yyyy HH:mm"),
    },
    {
        title: "Size (KB)",
        dataIndex: "file_Size_Bytes",
        key: "file_Size_Bytes",
        render: (size) => Math.floor(size / 1024) + " KB",
    },
    {
        title: "File-Hash",
        dataIndex: "file_Hash",
        key: "file_Hash",
        render: (hash) => <code>{hash.substring(0, 10)}</code>,
    },
    {
        title: "XML-Version",
        dataIndex: "xml_Version",
        align: "center",
        render: (version) => {
            if (version === "450") return <code>4.5</code>
            else if (version === "440") return <code>4.4</code>
        }
    },
    {
        title: "Status",
        dataIndex: "successful",
        key: "successful",
        render: (successful) => {
            let color = successful ? "green" : "volcano";
            let txt = successful ? "ERFOLGREICH" : "FEHLGESCHLAGEN";
            return (
                <Tag color={color} key={successful}>
                    {txt}
                </Tag>
            );
        },
        align: "center"
    },
];

const UploadHistoryTable = (props) => {
    return (
        <Table columns={columns} dataSource={props.data} rowKey="upload_Id" />
    );
};
export default UploadHistoryTable;
