import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import PropTypes from "prop-types";
import "./Login.css";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    logo: {
        background: "url('./logo_pharmaSuisse_wo_text.png')",
        width: 74,
        height: 80,
        marginBottom: 25,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    error: {
        color: "#da0d0d",
        fontWeight: 500,
    },
}));

const Login = ({ setAuthentication }) => {
    const classes = useStyles();
    const history = useHistory();

    const isMounted = React.useRef(false);
    const [email, setEmail] = React.useState();
    const [password, setPassword] = React.useState();
    const [error, setError] = React.useState(false);

    useEffect(() => {
        isMounted.current = true;
        return () => (isMounted.current = false);
    }, []);

    const loginUser = async (credentials) => {
        return axios
            .post("/users/authenticate", credentials)
            .then((response) => {
                if (response.status === 200) {
                    history.push("/");
                    return response.data;
                }
            })
            .catch((data) => {
                setError(true);
            });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const user = await loginUser({
            email,
            password,
        });
        if (user) setAuthentication(user);
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <div className={classes.logo} />
                <Typography component="h1" variant="h5">
                    Anmelden
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="E-Mail"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        // type="email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Passwort"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {error && (
                        <Grid container>
                            <Grid item xs>
                                <Typography className={classes.error}>
                                    E-Mail Adresse oder Passwort ist falsch.
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Anmelden
                    </Button>
                </form>
            </div>
        </Container>
    );
};

Login.propTypes = {
    setAuthentication: PropTypes.func.isRequired,
};

export default Login;
